
















import { Component, Vue } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { decrypt, encrypt } from '@/utils/aes';
import { ResponseBody } from '@/core/interfaces';
import { Route } from 'vue-router/types/router';
import md5 from 'md5';
@Component({
  beforeRouteEnter(to: Route, from: Route, next: any) {
    next((vm: Login) => {
      vm.sourcePage = from;
    })
  }
})
export default class Login extends Vue {

  formGroup = {
    username: null,
    password: null
  }
  rules = {
    username: { required: true, message: '请输入用户名！' },
    password: { required: true, message: '请输入密码！' }
  }

  /* 记录来源，登录成功后直接返回至来源页面 */
  sourcePage!: Route;

  public $refs!: {
    formGroup: ElForm
  };
  async submit() {
    let valid = await this.$refs.formGroup.validate();
    let { username, password } = this.formGroup;
    if (valid) {
      let res:any = await this.axios.post('permission/auth/login', { loginType: 2, mobile: username, md5Password: md5(password)});
      if (res.json) {
        const menuUrls = res.json.roles.reduce(function (last, next) {
          return `${last}${next.menuUrls == null ? '' : next.menuUrls}`
        }, '');
        localStorage.setItem('userInfo', JSON.stringify(res.json.user));
        localStorage.setItem('axbp_roles', JSON.stringify(menuUrls));
        localStorage.setItem('accessToken', JSON.stringify(res.json.accessToken))
        this.$router.push(this.sourcePage.path);
      } else {
        this.$message.warning(res.msg);
      }
    }
  };
  created() {
    if (localStorage.getItem('accessToken') && localStorage.getItem('userInfo')) {
      this.$router.push('/');
    }
  }
}
